import React from "react"
import Helmet from 'react-helmet';
import Layout from "../components/layout"
import { Link } from "gatsby";

const notFound = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className="404" style={{textAlign: "center", padding:"15vh 0", lineHeight: "1.5"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{maxWidth: "125px", maxHeight: "125px"}}><path className="fa-primary" d="M5.1 9.2C13.3-1.2 28.4-3.1 38.8 5.1l592 464c10.4 8.2 12.3 23.3 4.1 33.7s-23.3 12.3-33.7 4.1L9.2 42.9C-1.2 34.7-3.1 19.6 5.1 9.2z"/><path className="fa-secondary" d="M220.9 270L406.6 416.4C357.4 431 301.9 418.9 263 380c-29.9-29.9-44.3-70.2-42.2-110zM116.6 187.9l50.6 39.9-61.8 61.8C74 321 74 372 105.5 403.5c27.9 27.9 71.8 31.5 103.8 8.6l1.6-1.1c14.4-10.3 34.4-6.9 44.6 7.4s6.9 34.4-7.4 44.6l-1.6 1.1C189 505.3 110.2 498.7 60.2 448.8c-56.5-56.5-56.5-148 0-204.5l56.4-56.4zm215.1-10.6C306.6 152.1 269.1 147 239 162l-53.2-41.7C242.5 75.8 324.7 79.7 377 132c43.1 43.1 53.9 107.6 29.7 161.5l-53.2-41.7c5.8-26.2-1.7-54.5-21.7-74.5zm248 90.5l-90.5 90.5-50.7-39.8 96-96c31.5-31.5 31.5-82.5 0-114C506.7 80.6 462.7 77 430.7 99.9l-1.6 1.1c-14.4 10.3-34.4 6.9-44.6-7.4s-6.9-34.4 7.4-44.6l1.6-1.1C451 6.7 529.8 13.3 579.8 63.2c56.5 56.5 56.5 148 0 204.5z"/></svg>
        <h1 className="h2" style={{margin: "15px 0"}}>404: Broken Path</h1>
        <p className="about-text">You've hit a broken path in the digital wilderness.</p>
        <Link to="/" style={{margin: "auto", marginTop: "15px", textTransform: "unset"}} className="btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path className="fa-primary" d="M266.9 7.9C279-2.6 297-2.6 309.1 7.9l256 224c13.3 11.6 14.6 31.9 3 45.2s-31.9 14.6-45.2 3L288 74.5 53.1 280.1c-13.3 11.6-33.5 10.3-45.2-3s-10.3-33.5 3-45.2l256-224z"/><path className="fa-secondary" d="M64 270.5L64.1 472c0 22.1 17.9 40 40 40H184c22.1 0 40-17.9 40-40V383.7c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32V472c0 22.1 17.9 40 40 40h80.5c22.1 0 40-18 40-40.1l-.4-201.3L288 74.5 64 270.5z"/></svg>
          Return to Home
        </Link>
      </div>
    </Layout>
  )
}

export default notFound
